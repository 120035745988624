import * as React from "react";











const patrolCams = {
     "patrol-360-cam" : {
          name : "Patrol 360 Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "pbg360cam",
          camID : "patrol-360-cam",
          zoneMode : true,
          camIndex : 0,
          camPort : 8554
     },
     "front-right-cam" : {
          name : "Front Right Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "rightleftcam",
          camID : "front-right-cam",
          zoneMode : false,
          camIndex : 1,
          camPort : 8557
     },
     "front-left-cam" : {
          name : "Front Left Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "leftrightcam",
          camID : "front-left-cam",
          zoneMode : false,
          camIndex : 2,
          camPort : 8556
     },
     "back-right-cam" : {
          name : "Back Right Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "rightrightcam",
          camID : "back-right-cam",
          zoneMode : false,
          camIndex : 3,
          camPort : 8558
     },
     "back-left-cam" : {
          name : "Back Left Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "leftleftcam",
          camID : "back-left-cam",
          zoneMode : false,
          camIndex : 4,
          camPort : 8555
     },
     "right-zone-cam" : {
          name : "Right Zone Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "rightzone",
          camID : "right-zone-cam",
          zoneMode : true,
          camIndex : 5,
          camPort : 8560
     },
     "left-zone-cam" : {
          name : "Left Zone Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "leftzone",
          camID : "left-zone-cam",
          zoneMode : true,
          camIndex : 6,
          camPort : 8559
     },
     "front-zone-cam" : {
          name : "Front Zone Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "frontzone",
          camID : "front-zone-cam",
          zoneMode : true,
          camIndex : 7,
          camPort : 8561
     },
     "back-zone-cam" : {
          name : "Back Zone Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "backzone",
          camID : "back-zone-cam",
          zoneMode : true,
          camIndex : 8,
          camPort : 8562
     }
};











export {
     patrolCams
};